import React, { Component } from "react";

class KpointPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kPlayer: null,
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.id="kpoint-player-script";
    script.src = this.props.hostUrl || "https://ktpl.kpoint.com/assets/orca/media/embed/player-silk.js";
    script.async = true;

    const kpointPlayerScript= document.getElementById("kpoint-player-script");
    // if(!kpointPlayerScript){
    //   document.body.appendChild(script);
    // }
    document.body.appendChild(script);
    script.onload = () => {
      console.log("Script is loaded")      

    };
    document.addEventListener("kpointPlayerReady",(event)=>{
      const player= event.detail.player;

      if(player.info.kvideoId===this.props.videoId){
        this.setState({ kPlayer: player });
        if(this.props.onPlayerReady){
              this.props.onPlayerReady(player);  // Notify parent when player is ready
        }
        if (this.props.onStateChange) {
          player.addEventListener(player.events.onStateChange, this.props.onStateChange);
        }
      }
    })
  }

  componentWillUnmount() {
    if (this.state.kPlayer) {
      // Clean up: remove event listeners to avoid memory leaks
      if (this.props.onTimeupdate) {
        this.state.kPlayer.removeEventListener(this.state.kPlayer.events.timeUpdate, this.props.onTimeupdate);
      }
      if (this.props.onStateChange) {
        this.state.kPlayer.removeEventListener(this.state.kPlayer.events.onStateChange, this.props.onStateChange);
      }
    }
  }

  render() {
    return (
      <div
        data-video-host={this.props.videoHost}
        data-kvideo-id={this.props.videoId}
        data-package-id=""
        data-init-dynamic="true"
        data-player={this.props.playerId}
        style={{ width: this.props.width || '100%', height: this.props.height || 'auto' }}
        data-video-params='{
        "muted":false,"autoplay":false}'
      />
    );
  }
}

export default KpointPlayer;