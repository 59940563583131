import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Home.css';
const Home = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/Chatbot');
  };

  return (
    <div className="home-container">
        <div className="title-container">
            {/* <div className="title">Understand LI with Kpoint.ai</div>
            <div className="subtitle">Click on button, it'll navigate to chatbot</div> */}
            <div className="title">Welcome to Videobook on Understanding Life Insurance</div>
            
            <div className="subtitle">This videobook is designed to help you understand the Life Insurance industry and KPOINT's approach to customers</div>
            <div className="pdfname">Here are some sample questions to start with: 
              <br/>1.How do I analyze a life insurance company's annual report?
              <br/>2.What are the key performance indicators in the life insurance industry?
              <br/>3.What strategies does KPOINT use to start meaningful conversations with potential clients?
              <br/>4.How can I use industry data to enhance my sales pitch?
              <br/>5.What is the significance of persistence numbers in life insurance?
            </div>
        </div>
        
        <button onClick={handleRedirect} className="navigate-button">
        Go to Videobook
        </button>
    </div>
  );
};

export default Home;
